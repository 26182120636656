.btn-main {
  height: 40px;
  font-family: $body-font;
  color: $bg0;
  background-color: $green1;
  border-radius: 8px;
  padding: 8px 12px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;

}

.btn-secondary {
  height: 40px;
  line-height: 100%;
  font-family: $post-font;
  border: 2px solid $blue;
  color: $blue;
  padding: 8px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.btn-secondary:hover {
  color: $orange;
  border: 2px solid $orange;
  transition: 0.3s ease;
}

.btn-main:hover {
  color: $bg0;
  background-color: $orange;
  transition: 0.3s ease;
}
