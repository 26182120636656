.nav-bar {
  height: 80px;
  margin: 8px 0;
  border-bottom: 1px $grey solid;
  padding: 0;
  font-size: 1.05rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

terminal {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin: auto 0;
}

terminal-path {
  padding: 0;
  color: $blue;
  font-size: 0;
}

terminal-path a {
  color: $blue;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  font-weight: 500;
  text-decoration: none;
}

terminal-command {
  padding: 0;
  color: $light;
  font-weight: 500;
}

nav-icons a {
  color: $light;
}

nav-links {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
}

.blinking-cursor {
  animation: blink 1s infinite;
}

.nav-link-left {
  margin-right: 16px;
}

@keyframes blink {
0% {
  opacity: 1;
}

50% {
  opacity: 0;
}

100% {
  opacity: 1;
}
}

@media screen and (max-width: 992px) {
  .nav-bar {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 90px;
  }

  nav-links {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding-right: 1.5rem;
  }

  lang-select {
    margin-right: 1rem;
  }

  terminal {
    padding: 0.5rem 0 0 0.5rem;
  }

  .nav-link-left {
    margin: 0 0 2rem 0;
  }

  terminal-command {
    display: none
  }
}
