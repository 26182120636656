panes {
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: 100%;
}

pane-lf {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 1.5rem;
}

pane-rg {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px $grey solid;
  padding-left: 1.5rem;
}

pane-command {
  display: inline-block;
  height: 150px;
}

pane-display {
  display: inline-flex;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 992px) {
panes {
  display: flex;
    flex-direction: column;
    height: auto;
}

  pane-lf,
  pane-rg {
    padding: 0;
  }

  pane-lf {
    margin-bottom: 1.5rem;
  }

  pane-rg {
    border-left: none;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }

  pane-command {
    display: inline-block;
    height: auto;
    padding-bottom: 2rem;
  }

  .response {
    display: none;
  }
}
