#prj {
  font-family: $post-font;
  line-height: 1.75rem;
}

prj-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: wrap;
  padding-top: 1rem;
}

prj-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: wrap;
}

prj-info * {
  margin: 0;
}

prj-info-links {
  display: inline-block;
}

prj-ext-links {
  display: inline-block;
  align-items: baseline;
}

.mobile-project-info {
  display: none;
}

.mobile-project-info-text {
  display: none;
}

.project-image {
  border-radius: 8px;
  width: 240px;
  height: 240px;
  object-fit: cover;
  margin-right: 2rem;
}

prj-icons {
  width: fit-content;
  margin: 1.5rem 1rem 0 0;
}

prj-icons span.icon {
  font-family: $nerd-font;
  font-size: 48px;
  margin-right: 1.5rem;
}

prj-challenges {
  color: $light;
  padding-top: 1rem;
}

@media screen and (max-width: 992px) {

  prj-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: wrap;
  }

  prj-info h1 {
    margin: 2rem 0 2rem 0;
  }

  .project-image {
    border-radius: 8px;
    width: 100%;
    object-fit: cover;
    justify-items: center;
    margin-right: 0;
  }

  .project-info-description {
    display: none;
  }

  .mobile-project-text {
    display: flex;
  }

  .mobile-project-info {
    display: flex;
  }

  .mobile-project-info-description {
    display: flex;
  }

  .mobile-project-info-text {
    display: block;
  }

  .project {
    display: flex;
    flex-direction: column;
  }

  prj-icons span.icon {
    font-size: 40px;
  }
}
