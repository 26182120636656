card-grid {
  width: 100%;
  display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  grid-auto-rows: minmax(100px, auto);
}

.card-project {
  display: grid;
  grid-template-rows: 1fr 1fr;
  color: $orange;
  background-color: #112A2D;
  border-radius: 8px;
  height: 200px;
  max-width: 500px;
}

.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.card-svg {
  height: 48px;
  width: 48px;
}

.card-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-project-text {
  margin-bottom: 1rem;
  text-align: center;
  text-justify: end;
}

.card-project:hover {
  color: #0b1c1e;
  background-color: $orange;
}

.card-project:hover .card-icon {
  filter: brightness(0) saturate(100%) sepia(100%) hue-rotate(180deg) contrast(1);
}

// mobile
@media screen and (max-width: 576px) {
  card-grid {
    grid-template-columns: 1fr;
  }
}
