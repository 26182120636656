.about-icons {
  font-size: 4rem;
}

.icon-set {
  background-color: #112A2D;
  border-radius: 8px;
  max-height: 200px;
  max-width: 500px;
  padding: 2rem;
  gap: 1rem;
}

