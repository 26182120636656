footer {
  height: 24px;
}

.fs-24 {
  font-size: 24px;
}

.powerline-text {
  padding-left: 16px;
  padding-right: 16px;
}
