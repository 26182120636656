// color palette
$bg0: #00181E;
$light: #d3c6aa;
$light-fade: hsl(220, 19%, 54%, 0.6);
$blue: #5EB1D4;
$blue-dark: #366B81;
$orange: #ff8349;
$green0: #76946A;
$green1: #98BB6C;
$green2: #ADAF2C;
$purple: #934e69;
$red: #E46876;
$grey: #547878;
$light-grey: #B6BECD;
$white: #d8dee9;
$yellow: #fabd2f;
$pink: #D27E99;


// foreground
.bg0 {
  color: $bg0;
}

.light-grey {
  color: $light-grey;
}

.white {
  color: $white;
}

.red {
  color: $red;
}

.purple {
  color: $purple;
}

.orange {
  color: $orange;
}

.light {
  color: $light;
}

.light-fade {
  color: $light-fade;
}

.green {
  color: $green1;
}

.green2 {
  color: $green2;
}

.yellow {
  color: $yellow;
}

.blue-dark {
  color: $blue-dark;
}

.blue {
  color: $blue;
}

.yellow {
  color: $yellow;
}

.grey {
  color: $grey;
}

.pink {
  color: $pink;
}


//background
.bg-light-grey {
  background-color: $light-grey;
}

.bg-white {
  background-color: $white;
}

.bg-red {
  background-color: $red;
}

.bg-purple {
  background-color: $purple;
}

.bg-orange {
  background-color: $orange;
}

.bg-light {
  background-color: $light;
}

.bg-light-fade {
  background-color: $light-fade;
}

.bg-green {
  background-color: $green1;
}

.bg-blue {
  background-color: $blue;
}
