body {
  background-color: $bg0;
  color: $grey;
  font-family: $body-font;
}

main {
  height: calc(100vh - 96px);
  padding-top: 16px;
  padding-bottom: 16px;
}


.readme {
  max-width: 65ch;
  font-family: $post-font;
}

.mono {
  font-family: $body-font;
}

.nerd {
  font-family: $nerd-font;
}


.sans {
  font-family: $post-font;
}

.comment {
  color: $grey;
  font-family: $body-font;
  font-style: italic;
  font-size: 1rem;
  margin: 0;
}

.link {
  color: $green1;
}

.link:hover {
  color: $orange;
  transition: 0.2s ease;
}

.hover:hover {
  color: $orange;
  transition: 0.2s ease;
}

.italic-text:hover {
  font-style: italic;
  transition: 0.3s ease;
}

@media screen and (max-width: 992px) {
  .mobile-container {
    padding: 16px 16px;
  }

  .line-break-mobile {
    display: none;
  }

  line-break .mobile-break {
    border-bottom: 1px solid $grey;
    margin: 3rem 1rem 3rem 0;
  }
}
