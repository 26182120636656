@font-face {
  font-family: 'Monaspace';
  src: url('frontend/assets/fonts/Monaspace.ttf') format('truetype');
}

@font-face {
  font-family: 'Blex';
  src: url('frontend/assets/fonts/Blex.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  src: url('frontend/assets/fonts/DM-Sans.ttf') format('truetype');
}

@font-face {
  font-family: 'Mona Sans';
  src: url('frontend/assets/fonts/MonaSans.ttf') format('truetype');
}


$body-font: 'Monaspace', monospace;
$nerd-font: 'Blex', monospace;

$post-font: 'DM Sans', sans-serif;

// font-weights
.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

// font-sizes

